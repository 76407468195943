// Select
export const S = (e, s) => (s || document).querySelector(e);
// Select All
export const SA = (e, s) => [...((s || document).querySelectorAll(e))];
// Create element
export const DC = (e, cl) => {
    const el = document.createElement(e);
    if (el) {
        el.className = cl || '';
    }
    return el;
}
// Create element
export const DCA = (e, cl, p) => {
    const el = document.createElement(e);
    el.className = cl || '';
    if (p) {
        p.appendChild(el);
    }
    return el;
}
// Create text
export const TEXT = (text = 'Text node!') => document.createTextNode(text);
// Remove class
export const RCL = (e, cl) => {
    const reg = new RegExp(cl, 'g');
    if (e) {
        e.className = e.className.replace(reg, '');
        e.className = e.className.replace(/\s+/g, ' ').trim();
    }
    
}
// Remove all class
export const RCLA = (els, cl) => {
    const reg = new RegExp(cl, 'g');
    if (els) {
        [...els].forEach(el => el.className = el.className.replace(reg, '').replace(/\s+/g, ' ').trim());
    }
    
}
// Add class
export const ACL = (e, cl) => {
    if (e) {
        e.className = e.className.indexOf(`show ${cl}`) > -1 ? `${e.className}` :  `${e.className || ''} ${cl}`;
    }
    
}

export const ACLA = (els, cl) => {
    if (els) {
        [...els].forEach(el => el.className.indexOf(`${cl}`) > -1 ? el.className :  `${el.className || ''} ${cl}`);
    }
}


export const E = (e, ev, f) => e ? e.addEventListener(ev, f) : null;
export const RE = (e, ev, f) => e.removeEventListener(ev, f);
export const ATTR = (e, attr, val) => {
    if (!val) {
        return e.getAttribute(attr);
    }
    e.setAttribute(attr, val);
};
