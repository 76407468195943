import { S, SA, DCA, E, RCLA, ACL } from '../utilities';
import gsap, { TimelineLite, TweenMax, TimelineMax } from 'gsap';

export default () => {
    const etalage = S('.etalage_block');
    const etalages = SA('.etalage_block .row');
    if (etalage) {
        const nav = DCA('div', 'nav slider', etalage);
        const btns = DCA('div', 'nav_btns', nav);
        const btnRight = DCA('span', 'fas fa-arrow-right', btns);
        const label = DCA('span', 'label', nav);
        const tl = new TimelineLite();
        let current = 0;
        let inter = 8;
        let timer;
        

        const updateView = () => {
            current = current > etalages.length - 1 ? 1 : current + 1;
            label.innerHTML = `${current}/${etalages.length}`;
            const row = etalages[current - 1];
            RCLA(etalages, 'current');
            ACL(row, 'current');
            [...etalages].forEach( eta => eta.style.zIndex = 1 );
            row.style.zIndex = 100;

            tl.from('.row.current', { duration: 2, opacity: 0, stagger: 0.25});
            tl.from('.row.current .title', { duration: 3, translateX: '-100px', ease: 'back' , opacity: 0, stagger: 0.25}, '-=1.5');
            tl.from('.row.current .subtitle', { duration: 3, translateX: '100px', ease: 'back' , opacity: 0, stagger: 0.25}, '-=2');
            tl.from('.row.current .btns', { duration: 3, translateX: '-100px', ease: 'back' , opacity: 0, stagger: 0.25}, '-=2');
        };
        timer = setInterval(updateView, inter * 1000);
        
        E(btnRight, 'click', e => { clearInterval(timer); updateView()});
        updateView();
    }
    
}