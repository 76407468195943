export default () => {
    const Btn = document.querySelector('.mobile.nav');
    const Menu = document.querySelector('.main_menu');

    if (Btn) {
        Btn.addEventListener('click', () => {
            Menu.style.display =  Menu.style.display === 'block' ? 'none' : 'block';
        });
    }
    
    
};