import { S, SA, DCA, E, RCLA, ACL } from '../utilities';

export default  () => {
    const displays = SA('.img_display');
    let imgs = [];
    

    [...displays].forEach( display => {
        const list = SA('li span', display);
        const bigIMG = S('.big_img', display);
        let imgs = [...list].map( item => item.style.backgroundImage );

        const switcher = (current) => {
            bigIMG.style.backgroundImage = imgs[current];
            RCLA(list, 'current');
            ACL(list[current], 'current');
        };

        [...list].forEach( (item, i) => {
            E(item, 'click', e => {
                switcher(i); 
            });
        });

        switcher(0);
    });


    
}