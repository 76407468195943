import { S, SA, DCA, E, RCLA, ACL, DC, RE } from '../utilities';

export default () => {
    const displays = SA('.img_display');


    [...displays].forEach(display => {
        const lists = S('ul', display);
        const html = lists.innerHTML;
        const bigIMG = S('.big_img', display);
        const btnLighter = S('.btn_lightbox');
        const extractIMGURLS = html.replace(/\s+/g, '').split('url(');
        const imgs = extractIMGURLS.filter( (img, i) => i > 0 ).map( img => img.split(')')[0].replace(/\"/g, ''));
        const body = S('body');

        const lightBox = DC('div', 'light_box_widget');
        const closeBar = DCA('div', 'closeBar', lightBox);
        const closeBtn = DCA('div', 'closeBar_btn', closeBar);
        const closeBtn_1 = DCA('span', 'closeBar_btn_sp_1', closeBtn);
        const closeBtn_2 = DCA('span', 'closeBar_btn_sp_2', closeBtn);
        const lightBoxList = DCA('ul', 'light_box_widget_list', lightBox);
        const nav = DCA('div', 'light_box_nav nav slider', lightBox);
        const navBTN = DCA('div', 'light_box_nav nav_btns', nav);
        const icon = DCA('span', 'fas fa-arrow-right', navBTN);
        const navLabel = DCA('span', 'light_box_nav_label label', nav);
        const iconZoomer = DCA('span', 'fas fa-compress zoomer', bigIMG);

        let slides = [];   
        
        let currentIndex = 0;
        const switchSlide = () => {
            navLabel.innerHTML = `${currentIndex + 1}/${imgs.length}`;
            RCLA(slides, 'current');
            ACL(slides[currentIndex], 'current');
        }
        
        const goNext = () =>  {
            currentIndex = currentIndex < imgs.length - 1 ? currentIndex + 1 : 0;
            switchSlide();
        }

        const closeLight = () => {
            body.removeChild(lightBox);
        }

        const onHash = hash => {
            console.log(hash, 'helo');
        }
        const locationHashChanged = e => {
            console.log(e, 'helo');
        }

        const openLight = e => {
            const currentImage = e.currentTarget.style.backgroundImage;
            let img = ''; 
            if (currentImage) {
                img = currentImage.split('"')[1].split('"')[0];
            }
            
            body.appendChild(lightBox);
            
            imgs.forEach( (pic, i) => {
                let li = DC('li', 'light_box_widget_item'); 
                li.style.backgroundImage = `url(${pic})`;
                if (pic.indexOf(img) > -1) {
                    currentIndex = i;
                }
                slides.push(li);
                lightBoxList.appendChild(li);
            });
            RE(navBTN, 'click', goNext);
            E(navBTN, 'click', goNext);
            RE(closeBtn, 'click', closeLight);
            E(closeBtn, 'click', closeLight);
            switchSlide();
        }
        
        E(bigIMG, 'click', openLight);
        E(btnLighter, 'click', openLight);
    });
};