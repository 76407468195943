import { S, ACL, RCL, SA } from '../../utilities';
export default () => {
    const anchor = S('.anchor');
    const header = S('header');

    if (!!window.IntersectionObserver) {
        const observer = new IntersectionObserver((entries, observer) => { 
            entries.forEach( entry => {
                if (entry.intersectionRatio < 1) {
                    ACL(header, 'scrolling');
                } else {
                    RCL(header, 'scrolling');
                }
            });
        }, {
            rootMargin: '0px',
            threshold: 1.0
        });

        observer.observe(anchor)
    }

    
}