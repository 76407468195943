import { S, SA, DCA, E, RCLA, ACL } from '../utilities';
import gsap, { TimelineLite, TweenMax, TimelineMax } from 'gsap';

export default () => {
    const etalage = S('.project_slider');
    const holder = S('.project_slider .center');
    const etalages = SA('.project_slider .item');
    if (holder) {
        const nav = DCA('div', 'nav slider', holder);
        const btns = DCA('div', 'nav_btns', nav);
        const btnLeft = DCA('span', 'fas fa-arrow-left', btns);
        const btnRight = DCA('span', 'fas fa-arrow-right', btns);
        const label = DCA('span', 'label', nav);
        const tl = new TimelineLite();
        let current = 0;

        const switchPanels = () => {
            RCLA(etalages, 'current');
            ACL(etalages[current], 'current');
            label.innerHTML = `${current + 1}/${etalages.length}`;
        }
        switchPanels();


        E(btnRight, 'click', e => {
            current = current >= etalages.length - 1 ? 0 : current + 1;
            switchPanels();
        });
        E(btnLeft, 'click', e => {
            current = current < 1 ? etalages.length - 1 : current - 1;
            switchPanels();
        });
    }
    
}