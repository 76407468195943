import { ScrollScene, ScrollMagic } from 'scrollscene';
import gsap, { TimelineLite, TweenMax, TimelineMax } from 'gsap';

const bigTitle = () => {

    const tl = new TimelineLite();
    tl.from('.big_title h1', { duration: 2, opacity: 0, translateY: '-40%', ease: 'back', stagger: 0.25 });
    tl.from('.big_title .content', { duration: 2, opacity: 0, translateY: '-40%', ease: 'back', stagger: 0.25 }, '-=1.5');
    tl.from('.big_title .btns', { duration: 2, opacity: 0, translateY: '-40%', ease: 'back', stagger: 0.25 }, '-=1.5');
    tl.from('.big_title .attribs span', { duration: 1.5, translateY: '100%', ease: 'back', stagger: 0.25 }, '-=2');

    const scene = new ScrollScene({
        triggerElement: '.big_title',
        duration: 2000,
        gsap: {
            timeline: tl,
            reverseSpeed: 4
        },
    });
}


export default () => {
    
    const blokcolls = document.querySelectorAll('.block_layer.colls');   

    if (blokcolls) {
        const tl = new TimelineLite();
        tl.from('.block_layer.colls', { duration: 2, opacity: 0, stagger: 0.25 });
        tl.from('.block_layer.colls .left', { duration: 3, opacity: 0, translateX: '-100%', ease: 'expo', stagger: 0.25 }, '-=1.5');
        tl.from('.block_layer.colls .right', { duration: 3, opacity: 0, translateX: '20%', ease: 'back', stagger: 0.25 }, '-=2.5');


        // tl.from('.block_layer.colls .title.title_x2', { duration: 3, opacity: 0, translateX: '20%', ease: 'back', stagger: 0.25 }, '-=1.5');
        tl.from('.block_layer.colls .title.title_x2', { duration: 2, opacity: 0, translateX: '50%', ease: 'back', stagger: 0.25 }, '-=1.5');
        tl.from('.block_layer.colls .content', { duration: 2, opacity: 0, translateX: '50%', ease: 'back', stagger: 0.25 }, '-=1.5');
        tl.from('.block_layer.colls .btns', { duration: 2, opacity: 0, translateX: '50%', ease: 'back', stagger: 0.25 }, '-=1.5');


        const scene = new ScrollScene({
            triggerElement: '.block_layer.colls',
            duration: 1000,
            gsap: {
                timeline: tl,
                reverseSpeed: 2
            },
        });
    }
}