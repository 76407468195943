import { S, SA, DCA, E, RCLA, ACL, ACLA } from '../utilities';

export default () => {
    const accords = SA('.accordion');
    if (accords) {
        [...accords].forEach( data => {
            const content = data.innerHTML;
            let rmP = content.replace(/(<p>)?(<\/p>)?/g, '');
            let blocks = rmP.replace(/<strong>/g, '<div class="block"><div class="title_block"><i class="fas fa-angle-down"></i><strong>');
            blocks = blocks.replace(/<\/strong>/g, '</strong></div><p class="inner_content">');
            blocks = blocks.replace(/<div class\=\"block\">/g, '</p></div><div class="block">');
            blocks = blocks.replace('</div><div class="block">', '<div class="block">');
            blocks = `${blocks}</div>`;
            blocks = blocks.replace(/\s+/, '');
            blocks = blocks.replace(/\n/g, '');
            data.innerHTML = blocks;
        });
    
        const titles = SA('.title_block');
        const blocks = SA('.accordion .block');
        const icons = SA('.accordion .block i');
    
        const accordionToggler = (i) => {
            const parent = blocks[i];
            if (parent) {
                RCLA(blocks, 'current');
                RCLA(icons, 'fa-angle-up');
                ACLA(icons, 'fa-angle-down');
                ACL(parent, 'current');
                ACL(S('i', parent), 'fa-angle-up');
            }
        };
    
        [...titles].forEach( (title, i) => {
            E(title, 'click', e => {
                accordionToggler(i);
            });
        });
    
        accordionToggler(0);
    }
}